jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
 
    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });
    
});